var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[(_vm.cargando)?_c('loading'):_vm._e(),_c('div',{staticClass:"modal fade",staticStyle:{"background-color":"#00000082"},attrs:{"id":"modal-form-reporte-guia-export","tabindex":"-1","role":"dialog","aria-hidden":"true","data-backdrop":"static","data-keyboard":"false"}},[_c('div',{staticClass:"modal-dialog modal-xl"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v(" Generar Informe - "+_vm._s(_vm.informe.nombre)+" ")]),_c('button',{ref:"closeModal1",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"},on:{"click":function($event){return _vm.limpiarForm()}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_vm._m(0),_c('div',{staticClass:"tab-content",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"tab-pane fade active show",attrs:{"id":"Filtros"}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":""}},[_vm._v("Fecha Inicial")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filtros.fecha_inicial),expression:"filtros.fecha_inicial"}],staticClass:"form-control form-control-sm",class:_vm.$v.filtros.fecha_inicial.$invalid
                          ? 'is-invalid'
                          : 'is-valid',attrs:{"type":"date"},domProps:{"value":(_vm.filtros.fecha_inicial)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.filtros, "fecha_inicial", $event.target.value)},_vm.validaFechas]}})]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":""}},[_vm._v("Fecha Final")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filtros.fecha_final),expression:"filtros.fecha_final"}],staticClass:"form-control form-control-sm",class:_vm.$v.filtros.fecha_final.$invalid
                          ? 'is-invalid'
                          : 'is-valid',attrs:{"type":"date"},domProps:{"value":(_vm.filtros.fecha_final)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.filtros, "fecha_final", $event.target.value)},_vm.validaFechas]}})])]),_c('div',{staticClass:"row"},_vm._l((_vm.detalleInforme),function(detalle,index){return _c('div',{key:index,staticClass:"form-group col-md-4"},[(detalle.campo_lista_informe.tipo_dato == 1)?_c('div',[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(detalle.alias))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.campos[index]),expression:"campos[index]"}],staticClass:"form-control form-control-sm",attrs:{"type":"number"},domProps:{"value":(_vm.campos[index])},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.campos, index, $event.target.value)},function($event){return _vm.replaceForm(
                            detalle.campo_lista_informe.nombre_db,
                            _vm.campos[index]
                          )}]}})]):_vm._e(),(detalle.campo_lista_informe.tipo_dato == 2)?_c('div',[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(detalle.alias))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.campos[index]),expression:"campos[index]"}],staticClass:"form-control form-control-sm",attrs:{"type":"text"},domProps:{"value":(_vm.campos[index])},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.campos, index, $event.target.value)},function($event){return _vm.replaceForm(
                            detalle.campo_lista_informe.nombre_db,
                            _vm.campos[index]
                          )}]}})]):_vm._e(),(detalle.campo_lista_informe.tipo_dato == 5)?_c('div',[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(detalle.alias))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.campos[index]),expression:"campos[index]"}],staticClass:"form-control form-control-sm",attrs:{"type":"date"},domProps:{"value":(_vm.campos[index])},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.campos, index, $event.target.value)},function($event){return _vm.replaceForm(
                            detalle.campo_lista_informe.nombre_db,
                            _vm.campos[index]
                          )}]}})]):_vm._e(),(detalle.campo_lista_informe.tipo_dato == 7)?_c('div',[_c('label',[_vm._v(_vm._s(detalle.alias))]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.campos[index]),expression:"campos[index]"}],staticClass:"form-control form-control-sm",on:{"click":function($event){return _vm.replaceForm(
                            detalle.campo_lista_informe.nombre_db,
                            _vm.campos[index]
                          )},"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.campos, index, $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listas(detalle.campo_lista_informe)),function(lista){return _c('option',{key:lista.numeracion,domProps:{"value":lista.numeracion}},[_vm._v(" "+_vm._s(lista.descripcion)+" ")])})],2)]):_vm._e(),(detalle.campo_lista_informe.tipo_dato == 8)?_c('div',[_c('label',[_vm._v(_vm._s(detalle.alias))]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:_vm.$store.getters.getDarkMode ? 'dark-vselect' : '',attrs:{"placeholder":"Descripcion","label":_vm.descripcionListas(detalle.campo_lista_informe),"options":_vm.listas(detalle.campo_lista_informe)},on:{"input":function($event){return _vm.replaceForm(
                            detalle.campo_lista_informe.nombre_db,
                            _vm.campos[index].id
                          )}},model:{value:(_vm.campos[index]),callback:function ($$v) {_vm.$set(_vm.campos, index, $$v)},expression:"campos[index]"}})],1):_vm._e()])}),0)])])])]),_c('div',{staticClass:"modal-footer"},[(
              _vm.$store.getters.can('hidrocarburos.informeAutomatico.export')
            )?_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.filtros.$invalid),expression:"!$v.filtros.$invalid"}],staticClass:"btn bg-gradient-success",attrs:{"type":"button"},on:{"click":function($event){return _vm.generarListado(1)}}},[_c('i',{staticClass:"far fa-file-excel"}),_vm._v(" Excel ")]):_vm._e(),(
              _vm.$store.getters.can('hidrocarburos.informeAutomatico.pivotTable')
            )?_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.filtros.$invalid),expression:"!$v.filtros.$invalid"}],staticClass:"btn bg-gradient-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.generarListado(2)}}},[_c('i',{staticClass:"fas fa-list"}),_vm._v(" Tabla Dinámica ")]):_vm._e()])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"nav nav-tabs",attrs:{"id":"myTab","role":"tablist"}},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"id":"tab-Filtros","data-toggle":"tab","href":"#Filtros"}},[_vm._v("Filtros ")])])])
}]

export { render, staticRenderFns }