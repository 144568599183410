<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form-reporte-guia-export"
      style="background-color: #00000082"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Generar Informe - {{ informe.nombre }}
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
              @click="limpiarForm()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="Filtros">
                <div class="card-body">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label for="">Fecha Inicial</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="filtros.fecha_inicial"
                        :class="
                          $v.filtros.fecha_inicial.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @input="validaFechas"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="">Fecha Final</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="filtros.fecha_final"
                        :class="
                          $v.filtros.fecha_final.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @input="validaFechas"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="form-group col-md-4"
                      v-for="(detalle, index) in detalleInforme"
                      :key="index"
                    >
                      <!--Tipo Dato Numerico -->
                      <div v-if="detalle.campo_lista_informe.tipo_dato == 1">
                        <label for="">{{ detalle.alias }}</label>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="campos[index]"
                          @input="
                            replaceForm(
                              detalle.campo_lista_informe.nombre_db,
                              campos[index]
                            )
                          "
                        />
                      </div>
                      <!--Tipo Dato Text -->
                      <div v-if="detalle.campo_lista_informe.tipo_dato == 2">
                        <label for="">{{ detalle.alias }}</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="campos[index]"
                          @input="
                            replaceForm(
                              detalle.campo_lista_informe.nombre_db,
                              campos[index]
                            )
                          "
                        />
                      </div>
                      <!--Tipo Dato Fecha -->
                      <div v-if="detalle.campo_lista_informe.tipo_dato == 5">
                        <label for="">{{ detalle.alias }}</label>
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="campos[index]"
                          @input="
                            replaceForm(
                              detalle.campo_lista_informe.nombre_db,
                              campos[index]
                            )
                          "
                        />
                      </div>
                      <!--Tipo Dato Lista -->
                      <div v-if="detalle.campo_lista_informe.tipo_dato == 7">
                        <label>{{ detalle.alias }}</label>
                        <select
                          class="form-control form-control-sm"
                          v-model="campos[index]"
                          @click="
                            replaceForm(
                              detalle.campo_lista_informe.nombre_db,
                              campos[index]
                            )
                          "
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="lista in listas(detalle.campo_lista_informe)"
                            :key="lista.numeracion"
                            :value="lista.numeracion"
                          >
                            {{ lista.descripcion }}
                          </option>
                        </select>
                      </div>

                      <!--Tipo Dato v-Lista -->
                      <div v-if="detalle.campo_lista_informe.tipo_dato == 8">
                        <label>{{ detalle.alias }}</label>
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          class="form-control form-control-sm p-0"
                          v-model="campos[index]"
                          placeholder="Descripcion"
                          :label="
                            descripcionListas(detalle.campo_lista_informe)
                          "
                          :options="listas(detalle.campo_lista_informe)"
                          @input="
                            replaceForm(
                              detalle.campo_lista_informe.nombre_db,
                              campos[index].id
                            )
                          "
                        ></v-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn bg-gradient-success"
              @click="generarListado(1)"
              v-show="!$v.filtros.$invalid"
              v-if="
                $store.getters.can('hidrocarburos.informeAutomatico.export')
              "
            >
              <i class="far fa-file-excel"></i> Excel
            </button>

            <button
              type="button"
              class="btn bg-gradient-primary"
              @click="generarListado(2)"
              v-show="!$v.filtros.$invalid"
              v-if="
                $store.getters.can('hidrocarburos.informeAutomatico.pivotTable')
              "
            >
              <i class="fas fa-list"></i> Tabla Dinámica
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
export default {
  name: "ReporteModal",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      informe: {},
      detalleInforme: [],
      filtros: {
        fecha_inicial: null,
        fecha_final: null,
      },
      form: {},
      campos: [],
      listasForms: {
        productos_liquidos: [],
        tipo_vehiculos: [],
        empresas: [],
        estados_guias: [],
        tipos_combustible: [],
        categoria_operaciones: [],
        sitios_origenes: [],
        sitios_destinos: [],
        users: [],
      },
    };
  },
  validations: {
    filtros: {
      fecha_inicial: {
        required,
      },
      fecha_final: {
        required,
      },
    },
  },
  methods: {
    llenarModal(informe) {
      this.informe = informe;
      let informesFiltrados = []; // Crear un array temporal para almacenar informes únicos

      for (let k = 0; k < this.informe.det_informes.length; k++) {
        if (this.informe.det_informes[k].campo_lista_informe.filtro === 1) {
          const nuevoDato = this.informe.det_informes[k];

          // Verificar si el nuevo dato ya está en el array temporal
          const existeDetalle = informesFiltrados.some(
            (item) => item.id === nuevoDato.id
          );

          // Si no existe, agregarlo al array temporal
          if (!existeDetalle) {
            informesFiltrados.push(nuevoDato);
          }
        }
      }

      // Asignar el array temporal al array final
      this.detalleInforme = informesFiltrados;
    },

    replaceForm(nombre_bd, contenido) {
      this.$set(this.form, nombre_bd, contenido);
    },

    listas(campo) {
      /* Lista Origen, Destino */
      if ([4, 5].includes(campo.id)) {
        return (
          this.listasForms.sitios_origenes, this.listasForms.sitios_destinos
        );
      }
      /* Lista Tipo Vehículo */
      if ([20].includes(campo.id)) {
        return this.listasForms.tipo_vehiculos;
      }
      /* Lista Empresa */
      if ([8].includes(campo.id)) {
        return this.listasForms.empresas;
      }
      /* Lista Ciudad Origen*/
      if ([6].includes(campo.id)) {
        return this.listasForms.ciudades;
      }
      /* Lista Producto Transportado*/
      if ([7].includes(campo.id)) {
        return this.listasForms.productos_liquidos;
      }
      /* Lista Despachado A*/
      if ([9].includes(campo.id)) {
        return this.listasForms.despachados;
      }
      /* Lista User*/
      if ([17, 23].includes(campo.id)) {
        return this.listasForms.users;
      }
      /* Lista Estado Guía*/
      if ([22].includes(campo.id)) {
        return this.listasForms.estados_guias;
      }
      /* Lista Tipo Combustible*/
      if ([25].includes(campo.id)) {
        return this.listasForms.tipos_combustible;
      }
      /* Lista Categoria Operación*/
      if ([26].includes(campo.id)) {
        return this.listasForms.categoria_operaciones;
      }
    },

    descripcionListas(campo) {
      /* Lista Origen, Destino */
      if ([4, 5].includes(campo.id)) {
        return "nombre";
      }
      if ([17, 23].includes(campo.id)) {
        return "name";
      }
      /* Lista Tipo Vehículo */
      if ([20].includes(campo.id)) {
        return "nombre";
      }
      /* Lista Empresa */
      if ([8].includes(campo.id)) {
        return "razon_social";
      }
      /* Lista Ciudad Origen*/
      if ([6].includes(campo.id)) {
        return "nombre";
      }
      /* Lista Producto Transportado*/
      if ([7].includes(campo.id)) {
        return "nombre";
      }
    },

    getOrigenes() {
      axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.sitios_origenes = response.data;
      });
    },

    getDestinos() {
      axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.sitios_destinos = response.data;
      });
    },

    getTipoVehiculo() {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 2,
          },
        })
        .then(function(response) {
          me.listasForms.tipo_vehiculos = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getEmpresas() {
      let me = this;
      var url = "api/admin/empresas/lista";
      axios
        .get(url, {
          params: { linea_negocio_id: 2 },
        })
        .then(function(response) {
          me.listasForms.empresas = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getCiudades() {
      axios.get("/api/admin/ciudades/lista").then((response) => {
        this.listasForms.ciudades = response.data;
      });
    },

    getProductoLiquido() {
      let me = this;
      var url = "api/hidrocarburos/Productos/lista";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.productos_liquidos = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getDespachados() {
      axios.get("/api/lista/54").then((response) => {
        this.listasForms.despachados = response.data;
      });
    },

    getEstadosGuias() {
      axios.get("/api/lista/37").then((response) => {
        this.listasForms.estados_guias = response.data;
      });
    },

    getTiposCombustibles() {
      axios.get("/api/lista/24").then((response) => {
        this.listasForms.tipos_combustible = response.data;
      });
    },

    getCategoriasOperaciones() {
      axios.get("/api/lista/182").then((response) => {
        this.listasForms.categoria_operaciones = response.data;
      });
    },

    getUser() {
      axios.get("/api/admin/usuarios/lista").then((response) => {
        this.listasForms.users = response.data;
      });
    },

    validaFechas() {
      if (this.filtros.fecha_inicial && this.filtros.fecha_final) {
        var fecha_menor = new Date(this.filtros.fecha_inicial);
        var fecha_mayor = new Date(this.filtros.fecha_final);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.filtros.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }

      if (this.filtros.fecha_inicial && this.filtros.fecha_final) {
        fecha_menor = new Date(this.filtros.fecha_inicial).getTime();
        fecha_mayor = new Date(this.filtros.fecha_final).getTime();
        var diff = fecha_mayor - fecha_menor;
        var fecha_limite = diff / (1000 * 60 * 60 * 24);
        if (fecha_limite > 31) {
          this.filtros.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `Las fechas no pueden ser mayor a 31 dias...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }

      /* if (this.filtros.fecha_final) {
        var hoy = new Date();
        const fecha_final = new Date(this.filtros.fecha_final);
        fecha_final.setDate(fecha_final.getDate());
        if (fecha_final >= hoy) {
          this.filtros.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha final debe ser menor o igual a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      } */
    },

    limpiarForm() {
      this.$parent.getIndex();
      this.filtros = {
        fecha_inicial: null,
        fecha_final: null,
      };
      this.campos = [];
      this.form = {};
    },

    generarListado(opcion) {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/configuracionInformes/exportInformeAut",
        data: {
          informe_id: this.informe.id,
          form: this.form,
          filtros: this.filtros,
          opcion: opcion,
        },
      })
        .then((response) => {
          this.cargando = false;
          let data = response.data;
          if (!response.data.error) {
            if (opcion == 1) {
              this.cargando = false;
              location.href = data.url;
              this.$swal({
                icon: "success",
                title: response.data.message,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            } else {
              this.cargando = false;
              this.$refs.closeModal1.click();
              this.pivotTableReporte(data);
            }
          } else {
            this.$swal({
              icon: "error",
              title: response.data.error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    pivotTableReporte(data) {
      return this.$router.push({
        name: "/Hidrocarburos/ReportesAutomaticos/ReporteDinamico",
        params: { informe: data },
      });
    },
  },

  async mounted() {
    await this.getOrigenes();
    await this.getDestinos();
    await this.getTipoVehiculo();
    await this.getEmpresas();
    await this.getCiudades();
    await this.getProductoLiquido();
    await this.getDespachados();
    await this.getEstadosGuias();
    await this.getTiposCombustibles();
    await this.getCategoriasOperaciones();
    await this.getUser();
  },
};
</script>
